import React, { FormEvent, FunctionComponent, useEffect } from 'react';
import { Section } from '@/Components/Sections';
import Button from '@/Components/Button';
import Form from './Form';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import { useLayout } from '@/Contexts/LayoutContext';
import route from 'ziggy-js';
import API from '@/Services/API';
import { useNavigate } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';
import { useAuth } from '@/Contexts/AuthContext';
import moment from 'moment/moment';

const Create: FunctionComponent = () => {
	const { setHeader } = useLayout();
	const navigate = useNavigate();
	const { user } = useAuth();

	const { data, setData, setDate, errors, loadErrors } = useForm<Project>({
		id: undefined,
		name: '',
		owner_id: user?.id,
		date_of_inspection: moment().format('YYYY-MM-DD'),
		week_ending_date: moment().isoWeekday(5).format('YYYY-MM-DD'),
	});

	const submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		API.post(route('api.projects.store'), data).then(({ data }) => {
			navigate(reactRoute('projects.show', [data.data.id]));
		}).catch(({ response }) => loadErrors(response?.data?.errors));
	};

	useEffect(() => {
		setHeader && setHeader(
			<div className="flex w-100 gap-5">
				<a href="/">
					<Button type="button" className="back-link">
						<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
							<path
								d="M18.2 273l-17-17 17-17L171.8 85.4l17-17 33.9 33.9-17 17L93.1 232 424 232l24 0 0 48-24 0L93.1 280 205.8 392.6l17 17-33.9 33.9-17-17L18.2 273z"/>
						</svg>
						Back to Dashboard
					</Button>
				</a>
				<h2 className="leading-tight my-auto">Create Project</h2>
			</div>
		);
	}, []);

	useEffect(() => {
		if (moment(data.date_of_inspection).isValid()) {
			if (moment(data.date_of_inspection).isoWeekday(5).isValid() && moment(data.date_of_inspection).isoWeekday(5).isSameOrAfter(moment(data.date_of_inspection))) {
				setData && setData('week_ending_date', moment(data.date_of_inspection).isoWeekday(5).format('YYYY-MM-DD'));
			} else {
				setData && setData('week_ending_date', moment(data.date_of_inspection).add(1, 'weeks').isoWeekday(5).format('YYYY-MM-DD'));
			}
		}
	}, [data.date_of_inspection]);

	return (
		<div className="ProjectCreatePage">
			<Section>
				<form onSubmit={submit}>
					<Form data={data} setData={setData} setDate={setDate} errors={errors}/>
					<div className="flex clear-both  w-full float-leftjustify-end mt-6">
						<Button className="ml-auto btn-success" type="submit">Create Project</Button>
					</div>
				</form>
			</Section>
		</div>
	);
};

export default Create;
