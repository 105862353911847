import React, { FunctionComponent, useState } from 'react';
import Button from '@/Components/Button';
import Modal from '@/Components/Modals/Modal';
import API from '@/Services/API';
import route from 'ziggy-js';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import { useAuth } from '@/Contexts/AuthContext';
import Field from '@/Components/Inputs/Field';
import CopyIcon from '@/Components/CopyIcon';
import reactRoute from '@/Utilities/routes';
import { useNavigate } from 'react-router-dom';

interface IProps {
	project: Project;
}

interface IData {
	name?: string;
}

const DuplicateButton: FunctionComponent<IProps> = ({ project }) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [processing, setProcessing] = useState<boolean>(false);

	const navigate = useNavigate();

	const { data, setData } = useForm<IData>({
		name: project?.name ?? '',
	});

	const handleDuplicate = () => {
		setProcessing(true);
		API.post(route('api.projects.duplicate', { project: project.id }), data).then(({ data }) => {
			if (data?.project?.id > 0) {
				navigate(reactRoute('projects.show', [data?.project?.id], { tab: 'project' }));
			}
			setProcessing(false);
		}).catch(() => {
			setProcessing(false);
		});
	};

	return (
		<>
			<Button type="button" onClick={() => setOpenModal(!openModal)}>
				<CopyIcon/>
			</Button>

			{openModal && (
				<Modal openModal={openModal} onClose={() => setOpenModal(!openModal)}>
					<div>
						<h3 className="mb-3">Duplicate &quot;{project.name}&quot;</h3>

						<div className="grid grid-cols-1 gap-6 mb-6 clear-both w-full">
							<div className="col-span-1">
								<Field
									label="New Project Name *"
									name="name"
									value={data.name}
									onChange={setData}
								/>
							</div>
						</div>

						<div className="w-full flex justify-end mt-6">
							<Button type="button" onClick={handleDuplicate} disabled={processing}>Duplicate</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default DuplicateButton;