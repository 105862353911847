import React, { FunctionComponent, MutableRefObject, ReactNode } from 'react';
import ReactSelect from 'react-select';
import ReactSelectCreateable from 'react-select/creatable';
import Error from './Error';
import { ActionMeta, PropsValue } from 'react-select/dist/declarations/src/types';

interface IOption {
    name?: string | number;
    label?: string | number;
    value?: string | number;
}

interface IProps {
    options: IOption[] | any[];
    name?: string;
    className?: string;
    autoComplete?: string;
    required?: boolean;
    isFocused?: boolean;
    error?: string | unknown;
    placeholder?: string;
    value?: string | number | any;
    onChange?: (
        name: string,
        value?: string | number,
        option?: IOption[] | any[]
    ) => void;
    RenderIconLeft?: ReactNode | string;
    valueKey?: string;
    labelKey?: string;
    disabled?: boolean;
    innerRef?: MutableRefObject<any>;
    multiple?: boolean;
    createable?: boolean;
	isClearable?: boolean;
}

const Select: FunctionComponent<IProps> = ({
	options = [],
	name,
	value = null,
	className = '',
	autoComplete,
	required,
	isFocused,
	error,
	placeholder = '',
	onChange,
	RenderIconLeft,
	valueKey = 'value',
	labelKey = 'label',
	disabled = false,
	innerRef,
	multiple = false,
	createable = false,
	isClearable = false,
}) => {
	const mappedOptions = options.map((element) => ({
		...element,
		...{
			value: element[valueKey],
			label: element[labelKey]
		}
	}));

	const handleDefaultValue: PropsValue<any> = () => {
		if (Array.isArray(value)) {
			return value.map((item) => {
				if (typeof item === 'number' || typeof item === 'string') {
					item = mappedOptions?.find((option) => option[valueKey] == item);
				}

				if (item && Object.prototype.hasOwnProperty.call(item, valueKey) && Object.prototype.hasOwnProperty.call(item, labelKey)) {
					return {
						value: item[valueKey],
						label: item[labelKey]
					};
				}
			});
		}
		if (
			value !== null &&
            typeof value === 'object' &&
            Object.prototype.hasOwnProperty.call(value, valueKey) &&
            Object.prototype.hasOwnProperty.call(value, labelKey)
		) {
			return {
				value: value[valueKey],
				label: value[labelKey]
			};
		}
		if (value !== null && typeof value !== 'object') {
			return mappedOptions.filter((item) => item?.value === value);
		}

		return value;
	};

	const handleOnChange = (option: any, action: ActionMeta<IOption>) => {
		if (onChange) {
			if (multiple) {
				onChange(action.name ?? '', option.map((item: IOption) => item?.value), option);
			} else {
				onChange(action.name ?? '', option?.value, option);
			}
		}
	};

	const classes = error
		? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
		: 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200';

	return (
		<>
			{
				createable ? (
					<ReactSelectCreateable
						options={mappedOptions}
						name={name}
						placeholder={placeholder}
						defaultValue={handleDefaultValue}
						onChange={handleOnChange}
						required={required}
						//menuIsOpen={true}
						classNamePrefix="react-select"
						className={
							classes + ' block w-full ' + className + (RenderIconLeft && ' pl-10 ')
						}
						isDisabled={disabled}
						ref={innerRef}
						isMulti={multiple}
						isClearable={isClearable}
					/>
				) : (
					<ReactSelect
						options={mappedOptions}
						name={name}
						placeholder={placeholder}
						defaultValue={handleDefaultValue}
						onChange={handleOnChange}
						required={required}
						//menuIsOpen={true}
						classNamePrefix="react-select"
						className={
							classes + ' block w-full ' + className + (RenderIconLeft && ' pl-10 ')
						}
						isDisabled={disabled}
						ref={innerRef}
						isMulti={multiple}
						isClearable={isClearable}
					/>
				)
			}

			{typeof error === 'string' && <Error message={error}/>}
		</>
	);
};

export { Select as default, type IProps, type IOption };
