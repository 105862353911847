import React, { FunctionComponent, PropsWithChildren } from 'react';
import Field from '@/Components/Inputs/Field';
import { Project } from '@/Types/Models/Projects/project';
import { FormProps } from '@/Hooks/useForm';
import AsyncSelect from '@/Components/Inputs/AsyncSelect';
import Datepicker from '@/Components/Inputs/Datepicker';
import AddressAutocomplete, { IAddress } from '@/Components/Inputs/AddressAutocomplete';
import Select from '@/Components/Inputs/Select';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps extends FormProps<Project>, PropsWithChildren {
    project?: Project
}

const Form: FunctionComponent<IProps> = ({ project, data, errors, setData, setDate, children }) => {
	const { user } = useAuth();
	
	return (
		<div className="ProjectForm relative">
			<h3 className="float-left w-full mb-5">General Information</h3>
			<div className="grid grid-cols-3 gap-6 mb-6 clear-both w-full">
				<div className="col-span-1">
					<Field
						label="Project Name *"
						name="name"
						value={data.name}
						onChange={setData}
						error={errors?.name}
					/>
				</div>

				<div className="col-span-1">
					<Field name="client_id" label="Client *" error={errors?.client_id}>
						<AsyncSelect
							routeName="api.clients.index"
							selectRouteName="api.clients.show"
							selectParams={{ client: data.client_id }}
							allowNullSearch={true}
							labelKey="name"
							value={data.client_id}
							valueKey="id"
							onChange={(_, value) => setData && setData('client_id', parseInt(value))}
							placeholder="Select Client..."
						/>
					</Field>
				</div>

				{
					data?.client_id ? (
						<div key={data?.client_id} className="col-span-1">
							<Field name="project_manager_id" label="Project Manager *"
								   error={errors?.project_manager_id}>
								<AsyncSelect
									routeName="api.clients.project-managers.index"
									routeParams={{ client: data.client_id }}
									selectRouteName="api.clients.project-managers.show"
									selectParams={{ project_manager: data.project_manager_id }}
									allowNullSearch={true}
									labelKey="name"
									value={data.project_manager_id}
									valueKey="id"
									onChange={(_, value) => setData && setData('project_manager_id', parseInt(value))}
									placeholder="Select Project Manager..."
								/>
							</Field>
						</div>
					) : (
						<div className="hidden"/>
					)
				}

				<div className="col-span-1">
					<Field
						label="Main Contractor *"
						name="main_contractor"
						value={data.main_contractor}
						onChange={setData}
						error={errors?.main_contractor}
					/>
				</div>

				{
					user?.role?.name === 'administrator' && (
						<div className="col-span-1">
							<Field name="owner_id" label="Clerk of Works *" error={errors?.owner_id}>
								<AsyncSelect
									routeName="api.users.index"
									selectRouteName="api.users.show"
									selectParams={{ user: data.owner_id }}
									allowNullSearch={true}
									labelKey="name"
									value={data.owner_id}
									valueKey="id"
									onChange={(_, value) => setData && setData('owner_id', parseInt(value))}
									placeholder="Select Clerk of Works..."
								/>
							</Field>
						</div>
					)
				}
			</div>

			<h3 className="float-left w-full mb-5">Dates</h3>
			<div className="grid grid-cols-3 gap-6  mb-6   clear-both w-full">
				<div className="col-span-1">
					<Field
						label="Commencement Date"
						name="commencement_date"
					>
						<Datepicker
							name="commencement_date"
							type="date"
							value={data?.commencement_date}
							onChange={setDate}
						/>
					</Field>
				</div>

				<div className="col-span-1">
					<Field
						label="Completion Date"
						name="completion_date"
					>
						<Datepicker
							name="completion_date"
							type="date"
							value={data?.completion_date}
							onChange={setDate}
						/>
					</Field>
				</div>

				{
					project && (
						<div className="col-span-1">
							<Field
								label="Overrun Date"
								name="overrun_date"
							>
								<Datepicker
									name="overrun_date"
									type="date"
									value={data?.overrun_date}
									onChange={setDate}
								/>
							</Field>
						</div>
					)
				}

				<div className="col-span-1">
					<Field
						label="Date of Inspection *"
						name="date_of_inspection"
					>
						<Datepicker
							name="date_of_inspection"
							type="date"
							value={data?.date_of_inspection}
							onChange={setDate}
						/>
					</Field>
				</div>

				<div className="col-span-1">
					<Field
						label="Week ending date *"
						name="week_ending_date"
					>
						<Datepicker
							name="week_ending_date"
							type="date"
							value={data?.week_ending_date}
							onChange={setDate}
						/>
					</Field>
				</div>
			</div>

			<h3 className="float-left w-full mb-2">Address</h3>
			<div className="grid grid-cols-3 gap-6  mb-6   clear-both w-full">
				<div className="col-span-3">
					<AddressAutocomplete
						ukOnly={true}
						apiRoute="api.address.get-address"
						data={data?.address}
						onChange={(value) => setData && setData('address', value)}
						errors={(errors?.address as IAddress)}
					/>
				</div>
			</div>

			<h3 className="float-left w-full mb-5">Contacts</h3>
			<div className="grid grid-cols-3 gap-6 clear-both w-full">
				<div className="col-span-3">
					<Field
						label="Email List (To)"
						name="to_contacts"
						error={errors?.to_contacts}
					>
						<Select
							value={data.to_contacts}
							name="to_contacts"
							onChange={setData}
							labelKey="value"
							valueKey="value"
							createable={true}
							multiple={true}
							options={data?.to_contacts as any[]}
						/>
					</Field>
				</div>
				<div className="col-span-3">
					<Field
						label="Email List (CC)"
						name="cc_contacts"
						error={errors?.cc_contacts}
					>
						<Select
							value={data.cc_contacts}
							name="cc_contacts"
							onChange={setData}
							labelKey="value"
							valueKey="value"
							createable={true}
							multiple={true}
							options={data?.cc_contacts as any[]}
						/>
					</Field>
				</div>
			</div>

			{children}
		</div>
	);
};

export default Form;
